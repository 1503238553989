import { Interface } from '@ethersproject/abi'
import { ChainId } from '@beadswap/bsc-sdk'
import V_EXCHANGE_ABI from './v_exchange.json'
import V_FACTORY_ABI from './v_factory.json'

const V_FACTORY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xDf05e63b8Cc480787179c700132450105Ca62AAF',
  [ChainId.TESTNET]: '0xf5D915570BC477f9B8D6C0E980aA81757A3AaC36'
}

const V_FACTORY_INTERFACE = new Interface(V_FACTORY_ABI)
const V_EXCHANGE_INTERFACE = new Interface(V_EXCHANGE_ABI)

export { V_FACTORY_ADDRESSES, V_FACTORY_INTERFACE, V_FACTORY_ABI, V_EXCHANGE_INTERFACE, V_EXCHANGE_ABI }
